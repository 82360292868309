import React from 'react';
import { AppWrap } from '../../wrapper';
import { images } from '../../constants';
import { BsPhoneFill } from 'react-icons/bs';
import './Header.scss';

const Header = () => {
  return (
    <div className="home">
      <div className="container">
        <div className="image-container">
          <div className="image">
            <img src={images.bimage} alt='vellfar'/>
          </div>
        </div>
        <div className="content">
          <p className='sub-title'>innovation. technologies. systems</p>
          <h1 className='title'>Committed <span>To</span> Transform</h1>
          <p className='description'>Building <span>Solutions</span> to Streamline <span>Business Operations.</span></p>
          <p className='description'>Get in Touch Now!</p>
          <a href="#contact" className='email-link'>
            <button>
              <BsPhoneFill className="icon" /> Contact Sales!
            </button>
          </a>
        </div>
        
      </div>
    </div>
  )
}

export default AppWrap(Header, 'home');
