import React from 'react'
import { BsInstagram, BsXLg } from 'react-icons/bs';


const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div>
          <a href='https://www.instagram.com/vellfar_uganda?utm_source=qr&igsh=MzNlNGNkZWQ4Mg=='>
            <BsInstagram />
          </a>   
        </div>
        <div>
          <a href='https://x.com/vellfar_uganda'>
            <BsXLg />
          </a>
        </div>
    </div>
  )
}

export default SocialMedia