import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import logo from '../assets/v-logo-c.png';
import logo_image from '../assets/v-logo.jpg';
import bimage from '../assets/bimage.jpg';


export default {
  email,
  mobile,
  logo,
  logo_image,
  bimage
};